import { useRoute } from '#imports';

export const usePathParams = () => {
  const route = useRoute();

  /**
   * 渡されたデータが自然数か判定する
   * @param {unknown} value
   * @returns {boolean}
   */
  const isNatural = (value: unknown): boolean => {
    return typeof value === 'boolean' ||
      !Number.isInteger(Number(value)) ||
      Number(value) <= 0
      ? false
      : true;
  };

  /**
   * パスパラメータをすべて取得する
   */
  const getParams = () => {
    return route.query;
  };

  /**
   * パスパラメータを文字列として取得する
   * @param {string} key
   * @returns {string | undefined}
   */
  const getParamAsString = (key: string) => {
    const params = route.params[key];
    if (!params) return undefined;
    const value = Array.isArray(params) ? params.at(0) : params;

    return String(value);
  };

  /**
   * パスパラメータを数値として取得する
   * @param {string} key
   * @returns {number | undefined}
   */
  const getParamAsNumber = (key: string) => {
    const params = route.params[key];
    if (!params) return undefined;
    const value = Array.isArray(params) ? params.at(0) : params;

    // 自然数でない場合はundefinedを返す
    if (!isNatural(value)) return undefined;
    return Number(value);
  };

  /**
   * クエリパラメータを文字列として取得する
   * @param {string} key
   * @returns {string | undefined}
   */
  const getQueryAsString = (key: string) => {
    const query = route.query[key];
    if (!query) return undefined;
    const value = Array.isArray(query) ? query.at(0) : query;

    return String(value);
  };

  /**
   * クエリパラメータを数値として取得する
   * @param {string} key
   * @returns {number | undefined}
   */
  const getQueryAsNumber = (key: string) => {
    const query = route.query[key];
    if (!query) return undefined;
    const value = Array.isArray(query) ? query.at(0) : query;

    // 自然数でない場合はundefinedを返す
    if (!isNatural(value)) return undefined;
    return Number(value);
  };

  return {
    getParams,
    getQueryAsString,
    getQueryAsNumber,
    getParamAsString,
    getParamAsNumber,
  };
};
